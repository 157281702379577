import React from "react";
import PropTypes from "prop-types";
import { CircularProgress, Tab, Tabs } from "@material-ui/core";
import { Link } from "react-router";
import FlexBox from "./FlexBox";
import { Map } from "immutable";
import { formatText } from "../../helpers/FormatUtils";

const LinkTab = props => <Tab component={Link} {...props} />;

const CustomerSimpleTabs = props => (
  <Tabs {...props}>
    {props.isLoading && <CircularProgress size={20} color="secondary" />}
    {!props.isLoading &&
      props.items &&
      props.items.map((item, index) =>
        props.isLink ? (
          <LinkTab
            tabIndex={item.value}
            id={`nav-tab-${index}`}
            key={item.value}
            to={item.value}
            label={item.title}
            icon={item.icon}
            wrapped={true}
          />
        ) : (
          <Tab
            wrapped={true}
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              flex: index === 3 && "1 1 auto",
              backgroundColor: item.value === props.value ? "#fff" : "#f9f9f9",
              borderRadius: "12px",
            }}
            tabIndex={item.value}
            id={`tab-${index}`}
            key={index}
            value={item.value}
            // label={item.title}
            label={
              <FlexBox direction="row" align="center">
                <FlexBox
                  align="center"
                  style={{
                    color: item.value === props.value ? "#183E98" : "black",
                  }}
                >
                  {props.i18n.get(item.title, formatText(item.title))}
                </FlexBox>
                <FlexBox
                  style={{
                    color: item.value === props.value ? "white" : "black",
                    minWidth: 24,
                    fontSize: 12,
                    padding: "0px 4px",
                    textAlign: "center",
                    marginLeft: 8,
                    marginRight: 8,
                    borderRadius: 50,
                    backgroundColor:
                      item.value === props.value ? "#183E98" : "white",
                    display: "inline-block",
                    border: "1.5px solid var(--slate-200, #E2E8F0)",
                  }}
                >
                  {item.count}
                </FlexBox>
              </FlexBox>
            }
            icon={item.icon}
          />
        ),
      )}
  </Tabs>
);
CustomerSimpleTabs.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf({
      title: PropTypes.string,
      value: PropTypes.any,
      icon: PropTypes.element,
    }),
  ]),
  onChange: PropTypes.func,
  isLink: PropTypes.bool,
  variant: PropTypes.oneOf(["fullWidth", "scrollable", "standard"]),
  value: PropTypes.any,
  isLoading: PropTypes.bool,
  i18n: PropTypes.instanceOf(Map),
};

CustomerSimpleTabs.defaultProps = {
  variant: "standard",
};

export default CustomerSimpleTabs;
