import React, { useState } from "react";
import { Map } from "immutable";
import useSheet from "react-jss";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { CardContent, CircularProgress, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import SignInLink from "./SignInLink";
import CenteredCard from "../deprecated/CenteredCard";
import { getMessages } from "../../reducers/LocalizationReducer";
import { accent1 } from "../../../shared/theme/main-theme";
import PageLoading from "../ui-core/PageLoading";
import CustomButton, { OUTLINED, SECONDARY } from "../ui-core/CustomButton";
import { ArrowRight, Refresh } from "@material-ui/icons";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import { sendCode } from "../../actions/CustomerAuthActions";
import FlexBox, { ALIGN_CENTER, JUSTIFY_CENTER } from "../ui-core/FlexBox";
import { resetPasswordRequest } from "../../actions/AuthActions";
import { redirectUrl } from "../../containers/shared/RecoverPasswordContainer";
import FormPhoneField from "../form/FormPhoneField";
import { formatPhone2 } from "../../helpers/FormatUtils";
import ResendTimer from "./ResendTimer";

const enhancer = compose(
  connect(
    state => ({
      i18n: getMessages(state),
    }),
    {
      showErrorMessage,
      showSuccessMessage,
      resetPasswordRequest,
    },
  ),
  useSheet({
    title: { marginTop: "16px" },
    resetPasswordRequest: {
      marginTop: "1rem",
      width: "100%",
      backgroundColor: accent1,
    },
    signIn: {
      display: "block",
      textAlign: "center",
      marginBottom: "16px",
      textTransform: "uppercase",
    },
  }),
  reduxForm({
    form: "CustomerRecoverPasswordForm",
    validate: (values, { i18n }) => ({
      email:
        (!values.email || formatPhone2(values.email).length < 13) &&
        i18n.get("this_field_is_required"),
    }),
  }),
  getContext({ setLocation: PropTypes.func.isRequired }),
);

CustomerRecoverPasswordForm.propTypes = {
  classes: PropTypes.object,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  step: PropTypes.number,
  i18n: PropTypes.instanceOf(Map),
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  resetPasswordRequest: PropTypes.func,
  setLocation: PropTypes.func,
};

function CustomerRecoverPasswordForm({
  classes: { resetPasswordRequest: resetPasswordRequest2, signIn, title },
  handleSubmit,
  i18n,
  isLoading,
  resetPasswordRequest: resetPasswordRequest1,
  setLocation,
  showErrorMessage: showErrorMessage1,
  showSuccessMessage: showSuccessMessage1,
  step,
  submitting,
}) {
  const [code, setCode] = useState("");
  const [phone, setPhone] = useState("");
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [zero, setZero] = useState(false);
  const [enableToResend, setEnableToResend] = useState(true);

  const handleChange = event => {
    setCode(event.target.value);
  };

  return (
    <CenteredCard
      style={{ minWidth: "500px" }}
      withLogo={true}
      isLoading={submitting}
    >
      <PageLoading isLoading={isLoading} />
      <Helmet>
        <title>{i18n.get("recover_password", "Recover Password")}</title>
      </Helmet>
      {step === 1 ? (
        <CardContent>
          <form autoComplete="off" onSubmit={handleSubmit}>
            <h6 className={title}>
              {i18n.get("forgot_your_password", "Forgot your password?")}
            </h6>

            <FormPhoneField
              disableDropdown={true}
              disableAreaCodes={true}
              country="uz"
              name="email"
              variant={OUTLINED}
              size="small"
              style={{
                marginTop: 13,
                marginBottom: 13,
              }}
              fullWidth={true}
              onChange={(v, date) => {
                setPhone(date);
              }}
              label={`${i18n.get("phone_number")}*`}
            />

            <CustomButton type="submit" className={resetPasswordRequest2}>
              {i18n.get("recover_password", "Recover Password")}
            </CustomButton>
          </form>
        </CardContent>
      ) : (
        <CardContent>
          <TextField
            fullWidth={true}
            label={i18n.get("code")}
            value={code}
            onChange={handleChange}
            variant="outlined"
          />
          <FlexBox
            style={{ marginTop: "1rem" }}
            flex={true}
            align={ALIGN_CENTER}
            justify={JUSTIFY_CENTER}
          >
            {!enableToResend ? (
              <div />
            ) : (
              <FlexBox
                flex={true}
                justify="center"
                style={{ marginBottom: 13 }}
              >
                <ResendTimer
                  count={120}
                  onTimerIsEnd={() => {
                    setEnableToResend(false);
                    setZero(true);
                  }}
                />
              </FlexBox>
            )}
            {zero && (
              <CustomButton
                color={zero && SECONDARY}
                variant={OUTLINED}
                disabled={!zero}
                onClick={() => {
                  if (zero) {
                    resetPasswordRequest1(formatPhone2(phone), redirectUrl)
                      .then(() => {
                        showSuccessMessage1(i18n.get("successfully_submitted"));
                        setZero(false);
                        setEnableToResend(true);
                      })
                      .catch(error => showErrorMessage1(error));
                  }
                }}
                startIcon={<Refresh />}
              >
                {i18n.get("refresh")}
              </CustomButton>
            )}
          </FlexBox>
          <CustomButton
            className={resetPasswordRequest2}
            onClick={() =>
              sendCode(code, formatPhone2(phone))
                .then(res => {
                  setIsSendingCode(false);
                  if (res.status === "success") {
                    setLocation(redirectUrl + res.data);
                    showSuccessMessage1(
                      i18n.get("successfully_created", "Successfully Created"),
                    );
                  } else {
                    showErrorMessage1(res.message);
                  }
                })
                .catch(error => {
                  setIsSendingCode(false);
                  showErrorMessage1(error);
                })
            }
            endIcon={
              isSendingCode ? (
                <CircularProgress size={20} color="secondary" />
              ) : (
                <ArrowRight />
              )
            }
          >
            {i18n.get("verify_code", "Verify Code")}
          </CustomButton>
        </CardContent>
      )}
      <SignInLink className={signIn}>
        {i18n.get("sign_in", "Sign In")}
      </SignInLink>
    </CenteredCard>
  );
}

export default enhancer(CustomerRecoverPasswordForm);
