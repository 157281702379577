import React, { useState } from "react";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getMessage } from "../../reducers/LocalizationReducer";
import {
  showErrorMessage,
  showSuccessMessage,
} from "../../reducers/NotificationsReducer";
import {
  getCachedCustomer,
  getCustomerPredictions,
} from "../../api/admin/AdminCustomerApi";
import {
  getCachedPostcode,
  getPostcodePredictionsForOrder,
} from "../../api/admin/AdminPostcodesApi";
import {
  getCachedPostServices,
  getPostServicesPredictions,
} from "../../api/shared/OrderApi";
import {
  ERROR,
  LOADING,
  NOT_STARTED,
  SUCCESS,
} from "../../components/orders-core/AdminPostForm";
import { getPaymentMethods } from "../../api/v2/admin/AdminCustomerApi";
import { createOrder } from "../../api/customer/CustomerOrderApi";
import CustomerAppLayout from "../../components/customer/CustomerAppLayout";
import { getCustomer } from "../../reducers/CustomerReducer";
import CustomerPostFormSingle from "../../components/orders-core/CustomerPostFormSingle";
import { ORDER_LIST_URL } from "../../constants/CustomerPathConstants";
import { KeyboardArrowLeft } from "@material-ui/icons";
import CustomButton, {
  OUTLINED,
  SECONDARY,
} from "../../components/ui-core/CustomButton";
import FlexBox from "../../components/ui-core/FlexBox";

const enhancer = compose(
  getContext({
    setLocation: PropTypes.func.isRequired,
  }),
  connect(
    state => {
      const customer = getCustomer(state);
      return {
        merchantType: customer.get("merchant_type"),
        canCreateCodOrder: customer.get("can_create_cod_order"),
        getLocalisationMessage: (code, defaultMessage) =>
          getMessage(state, code, defaultMessage),
      };
    },
    { showErrorMessage, showSuccessMessage },
  ),
);

CustomerPostCreate.propTypes = {
  showErrorMessage: PropTypes.func,
  showSuccessMessage: PropTypes.func,
  getLocalisationMessage: PropTypes.func.isRequired,
  onSubmitFail: PropTypes.func,
  setLocation: PropTypes.func,
};

function CustomerPostCreate(props) {
  const { getLocalisationMessage } = props;
  const [isLoading, setIsLoading] = useState(NOT_STARTED);
  const [resMessage, setResMessage] = useState(false);
  const [orderId, setOrderId] = useState(0);
  const [barcode, setBarcode] = useState(0);

  return (
    <CustomerAppLayout
      title={getLocalisationMessage("create_new_order", "Create new order")}
      appTitleRightAction={
        <FlexBox align="center" justify="center">
          <CustomButton
            onClick={() => props.setLocation(ORDER_LIST_URL)}
            startIcon={<KeyboardArrowLeft />}
            variant={OUTLINED}
            color={SECONDARY}
          >
            {getLocalisationMessage("back", "Back")}
          </CustomButton>
        </FlexBox>
      }
    >
      <CustomerPostFormSingle
        setIsLoading={setIsLoading}
        setResMessage={setResMessage}
        isLoading={isLoading}
        orderId={orderId}
        orderBarcode={barcode}
        resMessage={resMessage}
        onSubmit={values => {
          setIsLoading(LOADING);
          createOrder(values)
            .then(res => {
              if (res.status === "success") {
                setOrderId(res.data.id);
                setBarcode(res.data.order_number);
                setIsLoading(SUCCESS);
                setResMessage(
                  getLocalisationMessage(
                    "successfully_created",
                    "Successfully Created",
                  ),
                );
                props.showSuccessMessage(
                  getLocalisationMessage(
                    "successfully_created",
                    "Successfully Created",
                  ),
                );
              } else {
                setIsLoading(ERROR);
                setResMessage(res.message);
                props.showErrorMessage(res.message);
              }
            })
            .catch(error => {
              setIsLoading(ERROR);
              setResMessage(error.reasonMessage);
              if (props.onSubmitFail) {
                props.onSubmitFail(error);
              } else {
                props.showErrorMessage(error);
              }
            });
        }}
        getCachedCustomer={getCachedCustomer}
        getCustomerPredictions={getCustomerPredictions}
        getCachedPostServices={getCachedPostServices}
        getPostServicesPredictions={getPostServicesPredictions}
        getCachedPostcode={getCachedPostcode}
        getPostcodePredictionsForOrder={getPostcodePredictionsForOrder}
        getPaymentMethods={getPaymentMethods}
      />
    </CustomerAppLayout>
  );
}

export default enhancer(CustomerPostCreate);
