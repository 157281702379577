import { injectReducer } from "../../shared/helpers/ReducerContext";
import { combineReducers } from "redux-immutable";

const SIDEBAR_STATUS = "CUSTOMER_SIDEBAR_STATUS/OPEN";

const selector = injectReducer(
  "sidebarStatus",
  combineReducers({
    sidebarStatus: (state = true, { type, payload }) => {
      switch (type) {
        case SIDEBAR_STATUS:
          return payload;

        default:
          return state;
      }
    },
  }),
);

export function getSidebarStatus(state) {
  return selector(state).get("sidebarStatus");
}

export const updateSidebarStatus = bool => ({
  payload: bool,
  type: SIDEBAR_STATUS,
});
