import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Card } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { getMessage } from "../../reducers/LocalizationReducer";
import FlexBox from "../ui-core/FlexBox";
import { getValue } from "../../helpers/DataUtils";
import { safeParseDate } from "../../helpers/DateUtils";
import { formatDateTimeToUrl } from "../../helpers/FormatUtils";
import { endOfToday, startOfToday, subDays } from "date-fns";
import { getCustomerId } from "../../reducers/CustomerReducer";
import CustomerDashboardFilterForm from "./CustomerDashboardFilterForm";
import MapMap from "./Map";
import { geoJson } from "./GeoJson";

const enhancer = compose(
  connect(state => ({
    customerId: getCustomerId(state),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

CustomerOrderCountByToJurisdictionWrapper.propTypes = {
  getLocalisationMessage: PropTypes.func,
  apiUrl: PropTypes.string,
  customerId: PropTypes.string,
};

function CustomerOrderCountByToJurisdictionWrapper(props) {
  const [chartData, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [note, setNote] = useState(null);
  const orderRef = React.useRef(null);

  const date = startOfToday();
  const date2 = endOfToday();

  const [filter, setFilter] = useState({
    from_date_time: formatDateTimeToUrl(subDays(date, 1)),
    to_date_time: formatDateTimeToUrl(subDays(date2, 1)),
    customer_id: props.customerId,
  });

  useEffect(() => {
    setIsLoading(true);
    props
      .apiUrl(filter)
      .then(r => {
        if (r && r.data) {
          const barData = getValue(r, "data.result");
          const newObject = {
            type: "FeatureCollection",
            features: geoJson.features.map(item => {
              const match = barData.find(
                item2 =>
                  item2.jurisdiction_id ===
                  getValue(item, "properties.jurisdiction_id"),
              );
              return {
                ...item,
                properties: {
                  ...item.properties,
                  name: match.label,
                  value: match.value,
                },
              };
            }),
          };

          setData(newObject);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setNote(props.getLocalisationMessage("no_data", "No Data"));
        setIsLoading(false);
        setData({});
      });
  }, [filter]);

  return (
    <Card
      style={{
        width: "100%",
        boxShadow: "rgba(145, 158, 171, 0.12)",
        borderRadius: "16px",
        paddingBottom: "26px",
        height: window.innerWidth > 1800 ? 850 : 650,
      }}
      ref={orderRef}
    >
      <FlexBox direction="row">
        <FlexBox
          flex={true}
          align="center"
          style={{
            marginTop: 10,
            marginLeft: 15,
            marginRight: 15,

            fontSize: 14,
          }}
          justify="space-between"
        >
          <FlexBox style={{ fontWeight: 700, fontSize: 16 }}>
            {props.getLocalisationMessage(
              "order_completion_count_across_uzbekistan",
              "Received",
            )}
          </FlexBox>
          <CustomerDashboardFilterForm
            initialValues={{
              fromDateTime: safeParseDate(filter.from_date_time),
              toDateTime: safeParseDate(filter.to_date_time),
            }}
            onSubmit={v => {
              setFilter({
                ...filter,
                from_date_time: formatDateTimeToUrl(v.fromDateTime),
                to_date_time: formatDateTimeToUrl(v.toDateTime),
              });
            }}
          />
        </FlexBox>
      </FlexBox>

      {isLoading && orderRef.current && (
        <FlexBox
          justify="center"
          align="flex-end"
          style={{ height: 400, position: "relative", gap: "10px" }}
        >
          {Array.from(
            { length: Math.ceil(orderRef.current.offsetWidth / 30) },
            () => Math.floor(Math.random() * 400),
          ).map(item => (
            <Skeleton style={{ transform: "none" }} width={20} height={item} />
          ))}

          <FlexBox
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
            align="center"
            justify="center"
          >
            <h4>
              <strong>
                {note ||
                  `${props.getLocalisationMessage("loading", "Loading")}...`}
              </strong>
            </h4>
          </FlexBox>
        </FlexBox>
      )}

      {!isLoading && (
        <FlexBox flex={true} justify="center" align="center">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                width: "90%",
                maxWidth: "1200px",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <MapMap
                width={
                  window.innerWidth > 1800
                    ? 1100
                    : window.innerWidth < 1600 && window.innerWidth > 1400
                    ? 800
                    : window.innerWidth < 1400
                    ? 600
                    : 900
                }
                height={window.innerWidth > 1800 ? 920 : 600}
                data={chartData}
              />
            </div>
          </div>
        </FlexBox>
      )}
    </Card>
  );
}

export default enhancer(CustomerOrderCountByToJurisdictionWrapper);
