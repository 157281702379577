import React from "react";
import useSheet from "react-jss";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { reduxForm, formValues } from "redux-form";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  withTheme,
} from "@material-ui/core";
import { connect } from "react-redux";
import FeedbackEmotions from "./FeedbackEmotions";
import FormRatingBar from "../form/FormRatingBar";
import FormTextField from "../form/FormTextField";
import PageLoading from "../ui-core/PageLoading";
import { renderIf } from "../../helpers/HOCUtils";
import { getMessage } from "../../reducers/LocalizationReducer";

const enhancer = compose(
  connect(state => ({
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  withTheme,
  renderIf("open"),
  useSheet({
    bodyDialog: { padding: "0" },
    comment: {
      margin: "0 12px",
      "& .MuiSvgIcon-root": {
        width: "38px",
        height: "38px",
      },
    },
    contentDialog: { width: "300px" },
    progress: { textAlign: "center", width: "88px" },
    title: { fontFamily: "Blogger Sans", padding: "18px" },
    actions: { "& button": { fontFamily: "Blogger Sans" } },
  }),
  reduxForm({
    form: "FeedbackDialog",
    enableReinitialize: true,
    initialValues: { score: 5 },
  }),
  formValues("score"),
);

FeedbackDialog.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  open: PropTypes.bool,
  onRequestClose: PropTypes.func,
  score: PropTypes.number,
  submitting: PropTypes.bool,
  getLocalisationMessage: PropTypes.func.isRequired,
  theme: PropTypes.object,
};

function FeedbackDialog(props) {
  const { classes, getLocalisationMessage } = props;

  return (
    <Dialog
      modal={true}
      actionsContainerClassName={classes.actions}
      PaperProps={{
        style: {
          width: 350,
        },
      }}
      open={props.open}
    >
      <DialogTitle
        style={{
          backgroundColor: props.theme.palette.primary1Color,
          color: props.theme.palette.appBarTextColor,
        }}
        className={classes.title}
      >
        {getLocalisationMessage("feedback", "Feedback")}
      </DialogTitle>
      <DialogContent>
        <PageLoading isLoading={props.submitting} />

        <FeedbackEmotions emotion={props.score} />

        <form className={classes.comment}>
          <FormRatingBar name="score" />

          <FormTextField
            name="note"
            fullWidth={true}
            label={getLocalisationMessage("add_comment", "Add Comment")}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <div>
          <Button disabled={props.submitting} onClick={props.onRequestClose}>
            {getLocalisationMessage("dismiss", "Dismiss")}
          </Button>
          <Button onClick={props.handleSubmit}>
            {getLocalisationMessage("submit", "Submit")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default enhancer(FeedbackDialog);
