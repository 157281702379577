import { compose, mapPropsStream } from "recompose";
import PropTypes from "prop-types";
import { MarkerClusterer, withMapInstance } from "react-leflet-map-components";
import React from "react";
import fp from "lodash/fp";
import { isEqualData } from "../../../helpers/DataUtils";
import { isEmpty } from "lodash";
import Icon from "../../maps-leaflet/assets/postcodes_with_uzpostlogo.svg";

const enhancer = compose(
  withMapInstance(),
  mapPropsStream(propsStream => {
    const markersStream = propsStream
      .filter(props => props.map)
      .distinctUntilKeyChanged("locations", isEqualData)
      .map(props =>
        props.locations.reduce((acc, location) => {
          if (isEmpty(location)) return acc;

          acc.push({
            lat: location.lat,
            lng: location.lon,
            customData: location,
            markerOption: {
              icon: props.maps.icon({
                iconUrl: Icon,
                iconSize: [45, 45],
                iconAnchor: [22, 45],
                popupAnchor: [0, -50],
              }),
            },
          });

          return acc;
        }, []),
      );

    return propsStream.combineLatest(markersStream, (props, markers) => ({
      ...props,
      markers,
    }));
  }),
);

LeafletMapDriversCluster.propTypes = {
  maps: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  markers: PropTypes.array,
  onClick: PropTypes.func,
};

function LeafletMapDriversCluster({ onClick, locations, ...props }) {
  return !props.markers ? null : (
    <MarkerClusterer
      markerOptions={{
        icon: props.maps.icon({
          iconUrl: Icon,
          iconSize: [45, 45],
          iconAnchor: [22, 45],
          popupAnchor: [0, -50],
        }),
      }}
      onClick={e => onClick(fp.get("layer.options.customData", e))}
      {...props}
    />
  );
}

export default enhancer(LeafletMapDriversCluster);
