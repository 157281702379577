import React, { useState } from "react";
import { compose, withHandlers } from "recompose";
import PropTypes from "prop-types";
import { formValues, reduxForm } from "redux-form";
import {
  Button,
  CardContent,
  IconButton,
  InputAdornment,
  makeStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import RecoverPasswordLink from "./RecoverPasswordLink";
import FormTextField from "../form/FormTextField";
import LinkWithNext from "../router/LinkWithNext";
import FacebookButton from "../ui-core/FacebookButton";
import CustomerCenteredCard from "../deprecated/CustomerCenteredCard";
import { withPreventingDefault } from "../../helpers/BrowserEventUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import { accent1, accent4 } from "../../../shared/theme/main-theme";
import { getAppFavicon } from "../../../shared/reducers/AppReducer";
import favicon from "../../assets/favicon.png";
import FlexBox, { JUSTIFY_END } from "../ui-core/FlexBox";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import FormPhoneField from "../form/FormPhoneField";
import { OUTLINED } from "../ui-core/CustomButton";
import {
  createNotEmptyValidator,
  createPhoneEmptyValidator,
} from "../../helpers/FormUtils";
import FormCustomToggle from "../form/FormCustomToggleV2";

const useStyles = makeStyles({
  btwMargin: {
    marginTop: "22px",
  },
  recoverPassword: {
    display: "block",
    fontSize: "12px",
    marginTop: "6px",
    lineHeight: "14px",
    position: "relative",
    marginBottom: "18px",
  },
  signUp: {
    display: "block",
    textAlign: "center",
    marginBottom: "16px",
    textTransform: "uppercase",
  },
  signIn: {
    width: "100%",
    backgroundColor: accent1,
    "&:hover": { backgroundColor: accent4 },
  },
  facebook: { width: "100%" },
});
const enhancer = compose(
  connect(state => ({
    appFavicon: getAppFavicon(state),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
  withHandlers({
    onSubmit: ({ onSubmit }) => values => (onSubmit ? onSubmit(values) : null),
  }),
  reduxForm({
    form: "SignIn",
    validate: (values, props) => ({
      username:
        !values.username &&
        props.getLocalisationMessage("this_field_is_required"),
      password:
        !values.password &&
        props.getLocalisationMessage(
          "password_is_required",
          "Password is required",
        ),
    }),
  }),
  formValues({
    merchant: "merchant",
  }),
);

CustomerSignInForm.propTypes = {
  submitting: PropTypes.bool,
  merchant: PropTypes.bool,
  handleSubmit: PropTypes.func,
  signUpUrl: PropTypes.string,
  appFavicon: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  onSignInWithFacebookClick: PropTypes.func,
  getLocalisationMessage: PropTypes.func,
  change: PropTypes.func,
};

function CustomerSignInForm({
  appFavicon,
  getLocalisationMessage,
  handleSubmit,
  isLoading,
  onSignInWithFacebookClick,
  signUpUrl,
  submitting,
  merchant,
  change,
}) {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const mFavicon = appFavicon || favicon;
  return (
    <CustomerCenteredCard withLogo={true} isLoading={submitting || isLoading}>
      <Helmet>
        <title>{getLocalisationMessage("sign_in", "Sign In")}</title>
        <link rel="icon" type="image/png" href={mFavicon} sizes="16x16" />
      </Helmet>
      <form noValidate={true} onSubmit={handleSubmit}>
        <CardContent>
          <FlexBox justify="center" align="center">
            <FormCustomToggle
              disabled={false}
              leftLabel={getLocalisationMessage("cash_client", "Cash Client")}
              rightLabel={getLocalisationMessage("merchant", "Merchant")}
              name="merchant"
              onChange={() => change("username", "")}
            />
          </FlexBox>
        </CardContent>

        <CardContent>
          {merchant ? (
            <FormTextField
              name="username"
              label={`${getLocalisationMessage(
                "corporate_inn_pinfl",
                "Corporate INN/PINFL",
              )}*`}
              fullWidth={true}
              type="number"
              onInput={e => {
                // eslint-disable-next-line radix
                e.target.value = e.target.value.toString().slice(0, 14);
              }}
              validate={createNotEmptyValidator(
                getLocalisationMessage("this_field_is_required"),
              )}
            />
          ) : (
            <FormPhoneField
              disableDropdown={true}
              disableAreaCodes={true}
              country="uz"
              name="username"
              variant={OUTLINED}
              size="small"
              style={{
                marginTop: 13,
                marginBottom: 13,
              }}
              fullWidth={true}
              label={`${getLocalisationMessage("phone_number")}*`}
              validate={createPhoneEmptyValidator(
                getLocalisationMessage(
                  "invalid_phone_number",
                  "Invalid phone number",
                ),
              )}
            />
          )}

          <FormTextField
            className={classes.btwMargin}
            name="password"
            fullWidth={true}
            label={`${getLocalisationMessage("password", "Password")} *`}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {!merchant && (
            <FlexBox flex={true} justify={JUSTIFY_END}>
              <RecoverPasswordLink
                tabIndex={-1}
                className={classes.recoverPassword}
              >
                {getLocalisationMessage("recover_password", "Recover Password")}
              </RecoverPasswordLink>
            </FlexBox>
          )}
          <Button
            type="submit"
            style={{
              marginTop: merchant ? 20 : 0,
            }}
            className={classes.signIn}
          >
            {" "}
            {getLocalisationMessage("sign_in", "Sign In")}{" "}
          </Button>

          {Boolean(onSignInWithFacebookClick) && (
            <FacebookButton
              className={classes.facebook}
              label={getLocalisationMessage(
                "sign_in_using_facebook",
                "Sign In using Facebook",
              )}
              onClick={withPreventingDefault(onSignInWithFacebookClick)}
            />
          )}
        </CardContent>

        {!merchant && Boolean(signUpUrl) && (
          <LinkWithNext to={signUpUrl} className={classes.signUp}>
            {getLocalisationMessage("sign_up", "Sign Up")}
          </LinkWithNext>
        )}
      </form>
    </CustomerCenteredCard>
  );
}

export default enhancer(CustomerSignInForm);
