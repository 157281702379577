import React, { useState } from "react";
import FlexBox from "./FlexBox";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import {
  Avatar,
  Card,
  Divider,
  IconButton,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
} from "@material-ui/core";
import cx from "classnames";
import {
  PROFILE_URL,
  SIGN_OUT_URL,
} from "../../constants/DashboardPathConstants";
import { Map } from "immutable";
import { MERCHANTS } from "../../constants/MerchantTypes";
import AppLanguageSelector from "../app-sidebar/AppLanguageSelector";
import PageLoading from "./PageLoading";
import { Link } from "react-router";
import { PersonOutline } from "@material-ui/icons";
import SignOutIcon from "../icons/SignOutIcon";

const useStyles = makeStyles({
  root: {
    minHeight: "80px",
    padding: "2px 0 2px 20px",
  },
  title: {
    fontSize: "24px",
    fontWeight: 700,
  },
  item: {
    "&.MuiListItemText-root": {
      "&>span": {
        color: "var(--black, #000)",
        textAlign: "left",
        fontSize: "16px",
        fontWeight: 500,
      },
      "&>p": {
        color: "var(--black, #000)",
        textAlign: "left",
        fontSize: "12px",
        fontWeight: 400,
      },
    },
  },
  avatar: {
    color: "#fff",
    backgroundColor: "#183E98",
    width: "30px",
    height: "30px",
    "& svg": {
      fill: "#fff",
    },
  },
  avatarMenu: {
    color: "#fff",
    backgroundColor: "#183E98",
    width: "50px",
    height: "50px",
    "& svg": {
      fill: "#fff",
    },
  },

  itemSignOut: {
    "&.MuiListItemText-root": {
      "&>p": {
        textAlign: "left",
        fontSize: "16px",
        fontWeight: 500,
      },
    },
  },
});

AppTitle.propTypes = {
  crumbs: PropTypes.node,
  children: PropTypes.node,
  orderList: PropTypes.bool,
  getLocalisationMessage: PropTypes.func,
  customer: PropTypes.instanceOf(Map),
};

function AppTitle(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);
  const openProfileMenu = Boolean(profileMenuAnchor);
  const handleAvatarOpen = event => {
    setProfileMenuAnchor(event.currentTarget);
  };
  const handleAvatarClose = () => {
    setProfileMenuAnchor(null);
  };

  return (
    <Card
      style={{
        minHeight: "90px",
        paddingBottom: "8px",
        borderBottom: "1.5px solid var(--slate-200, #E2E8F0)",
      }}
    >
      <PageLoading isLoading={isLoading} />
      <FlexBox
        className={cx(classes.root, "app-title")}
        justify="space-between"
        align="center"
      >
        <FlexBox direction="column" align="center" style={{ gap: 5 }}>
          {props.crumbs && (
            <Typography className={classes.title}>{props.crumbs}</Typography>
          )}
        </FlexBox>

        <FlexBox
          style={{ width: props.orderList ? "90%" : "80%", gap: 13 }}
          justify="flex-end"
        >
          {props.children}

          {props.children && (
            <FlexBox>
              <Divider orientation="vertical" style={{ width: 2 }} />
            </FlexBox>
          )}

          <FlexBox>
            <AppLanguageSelector setIsLoading={setIsLoading} />
          </FlexBox>

          <FlexBox>
            <Divider orientation="vertical" style={{ width: 2 }} />
          </FlexBox>

          <FlexBox align="center" justify="center">
            <FlexBox
              align="center"
              justify="center"
              style={{ marginRight: 13 }}
            >
              <IconButton
                onClick={handleAvatarOpen}
                style={{
                  padding: 10,
                  border: "1.5px solid #dfe0e4",
                  borderRadius: "50px",
                  marginRight: 13,
                }}
              >
                <Avatar className={classes.avatar} alt="Cindy Baker" src="" />
              </IconButton>
            </FlexBox>

            <Menu
              anchorEl={profileMenuAnchor}
              id="account-menu"
              open={openProfileMenu}
              PaperProps={{
                style: {
                  minWidth: 230,
                  marginTop: 30,
                  borderRadius: 10,
                  padding: "20px 20px 6px 20px",
                },
              }}
              onClose={handleAvatarClose}
              onClick={handleAvatarClose}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem>
                <ListItemAvatar
                  style={{
                    padding: 10,
                    border: "1.5px solid #dfe0e4",
                    borderRadius: "50px",
                    marginRight: 13,
                  }}
                >
                  <Avatar
                    className={classes.avatarMenu}
                    alt="Cindy Baker"
                    src=""
                  />
                </ListItemAvatar>
                <ListItemText>
                  {props.customer.get("merchant_type") === MERCHANTS ? (
                    <ListItemText
                      className={classes.item}
                      primary={props.customer.get("full_name")}
                      secondary={
                        <Typography>
                          {`${props.customer.getIn([
                            "company",
                            "name",
                          ])}/ ${props.customer.get("merchant_inn")}`}
                        </Typography>
                      }
                    />
                  ) : (
                    <ListItemText
                      className={classes.item}
                      primary={props.customer.get("full_name")}
                    />
                  )}
                </ListItemText>
              </MenuItem>
              <MenuItem
                component={Link}
                to={PROFILE_URL}
                style={{ paddingTop: 10, marginTop: 20 }}
              >
                <ListItemIcon>
                  <PersonOutline />
                </ListItemIcon>
                <ListItemText
                  className={classes.item}
                  primary={props.getLocalisationMessage("profile", "Profile")}
                />
              </MenuItem>
              <Divider />
              <MenuItem
                style={{ color: "#F44336", paddingBottom: 10 }}
                component={Link}
                to={SIGN_OUT_URL}
              >
                <ListItemIcon>
                  <SignOutIcon />
                </ListItemIcon>
                <ListItemText
                  className={classes.itemSignOut}
                  primary={
                    <Typography>
                      {props.getLocalisationMessage("sign_out", "Sign Out")}
                    </Typography>
                  }
                />
              </MenuItem>
            </Menu>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </Card>
  );
}

export default AppTitle;
