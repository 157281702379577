import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Card,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import CustomButton, { CONTAINED, SECONDARY } from "../ui-core/CustomButton";
import { getValue } from "../../helpers/DataUtils";
import { getMessage } from "../../reducers/LocalizationReducer";
import FlexBox from "../ui-core/FlexBox";
import { getCustomerTotalCountByServiceType } from "../../api/customer/CustomerDashboardApi";
import { getCustomerId } from "../../reducers/CustomerReducer";
import { formatText } from "../../helpers/FormatUtils";
import { getRandomColor } from "./AdminOrderCountByServiceTypeChartWrapper";
import CustomDoughnutChart from "../charts/CustomDoughnutChart";

const years = [2021, 2022, 2023, 2024, 2025];
const dataTypes = ["YEARLY", "MONTHLY"];
const months = [
  { label: "january", value: 1 },
  { label: "february", value: 2 },
  { label: "march", value: 3 },
  { label: "april", value: 4 },
  { label: "may", value: 5 },
  { label: "june", value: 6 },
  { label: "july", value: 7 },
  { label: "august", value: 8 },
  { label: "september", value: 9 },
  { label: "october", value: 10 },
  { label: "november", value: 11 },
  { label: "december", value: 12 },
];

const legendLabelLocalisation = (label, getLocalisationMessage) => {
  switch (label) {
    default:
      return getLocalisationMessage(label, formatText(label));
  }
};

export const formatColor = code => {
  switch (code) {
    case "BANDEROL":
      return "#FFC0CB";
    case "COURIER_SERVICE":
      return "#FFFF00";
    case "COURIER_SERVICE_WITHOUT_BAG":
      return "#FFFF00";
    case "LETTER":
      return "#0000FF";
    case "M_BAG":
      return "#FF00FF";
    case "ONE_STEP":
      return "#FFA500";
    case "ONE_STEP_WITHOUT_BAG":
      return "#FFA500";
    case "POSTCARD":
      return "#00FFFF";
    case "PARCEL":
      return "#008000";
    case "SEKOGRAMM":
      return "#FF0000";
    case "Xabarnoma":
      return "#A52A2A";
    case "SMALL_PACKETS":
      return "#800080";

    default:
      return getRandomColor();
  }
};

const useStyles = makeStyles(() => ({
  total: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    "@media (min-width: 1700px)": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
    },
  },
}));

const enhancer = compose(
  connect(state => ({
    customerId: getCustomerId(state),
    getLocalisationMessage: (code, defaultMessage) =>
      getMessage(state, code, defaultMessage),
  })),
);

CustomerOrderTotalCountByServiceTypeChartWrapper.propTypes = {
  getLocalisationMessage: PropTypes.func,
  customerId: PropTypes.string,
};

function CustomerOrderTotalCountByServiceTypeChartWrapper(props) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const monthlyOrderRef = React.useRef(null);
  const [dataType, setDataType] = useState("YEARLY");
  const [year, setYear] = useState(2025);
  const [month, setMonth] = useState(0);

  const [activeIndex, setActiveIndex] = useState([]);

  const styles = useStyles();

  const [filter, setFilter] = useState({
    year: 2025,
    type: dataType,
    customer_id: props.customerId,
  });

  useEffect(() => {
    setIsLoading(true);
    const filterNew = filter.month
      ? { ...filter }
      : {
          year: filter.year,
          type: filter.type,
          customer_id: props.customerId,
        };
    getCustomerTotalCountByServiceType(filterNew)
      .then(r => {
        if (r && r.data) {
          const barData = getValue(r, "data.result");
          setActiveIndex([]);

          setData(
            barData.map(item => ({
              ...item,
              color: formatColor(item.label),
            })),
          );
        }
        setIsLoading(false);
      })
      .catch(() => {
        setActiveIndex([]);

        setIsLoading(false);
        setData([]);
      });
  }, [filter]);

  return (
    <Card
      style={{
        width: "100%",
        padding: "15px",
        boxShadow: "rgba(145, 158, 171, 0.12)",
        borderRadius: "16px",
      }}
      ref={monthlyOrderRef}
    >
      <FlexBox direction="row">
        <FlexBox
          flex={true}
          style={{
            marginTop: 10,
            marginLeft: 15,
            marginRight: 15,
            fontSize: 14,
            width: "100%",
            gap: 13,
          }}
          direction="column"
          justify="space-between"
        >
          <FlexBox style={{ fontWeight: 700, fontSize: 16 }}>
            {props.getLocalisationMessage(
              "order_creation_count_by_shipment_type",
              "Order Count by Shipment Type",
            )}
          </FlexBox>
          <FlexBox flex={true} style={{ gap: 13, width: "100%" }}>
            <FormControl size="small" fullWidth={true}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={year}
                fullWidth={true}
                variant="outlined"
                onChange={event => setYear(event.target.value)}
              >
                {years.map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl size="small" fullWidth={true}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={dataType}
                variant="outlined"
                fullWidth={true}
                onChange={event => {
                  setDataType(event.target.value);
                  setMonth(0);
                }}
              >
                {dataTypes.map(item => (
                  <MenuItem key={item} value={item}>
                    {props.getLocalisationMessage(item)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {dataType === "MONTHLY" && (
              <FormControl size="small" fullWidth={true}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={month}
                  fullWidth={true}
                  variant="outlined"
                  onChange={event => setMonth(event.target.value)}
                >
                  {months.map(item => (
                    <MenuItem key={item} value={item.value}>
                      {props.getLocalisationMessage(item.label)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <CustomButton
              variant={CONTAINED}
              color={SECONDARY}
              fullWidth={true}
              disabled={dataType === "MONTHLY" && month === 0}
              style={{
                backgroundColor:
                  dataType === "MONTHLY" && month === 0
                    ? "lightgrey"
                    : "#3F51B5",
              }}
              onClick={() =>
                setFilter(prev => ({
                  ...prev,
                  year,
                  type: dataType,
                  month,
                }))
              }
            >
              {props.getLocalisationMessage("apply", "Apply")}
            </CustomButton>
          </FlexBox>
        </FlexBox>
      </FlexBox>
      {data && data.length === 0 && (
        <FlexBox justify="center" align="center" style={{ gap: "10px" }}>
          <FlexBox align="center" justify="center">
            <h4>
              <strong>
                {props.getLocalisationMessage("no_data", "No Data")}
              </strong>
            </h4>
          </FlexBox>
        </FlexBox>
      )}

      {!isLoading && (
        <FlexBox
          style={{
            marginTop: 10,
            borderTop: "2px solid rgba(0, 0, 0, 0.08)",
            height: "700px",
            position: "relative",
          }}
          justify="center"
          align="center"
        >
          <CustomDoughnutChart
            cutout="85%"
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            legendLabelLocalisation={legendLabelLocalisation}
            getLocalisationMessage={props.getLocalisationMessage}
            filter={filter}
            data={{
              labels: data.map(doughnut => doughnut.name),
              datasets: [
                {
                  data: data.map(doughnut => doughnut.value),
                  backgroundColor: data.map(doughnut => doughnut.color),
                  borderWidth: 1,
                  borderColor: "#fff",
                },
              ],
            }}
            position="right"
          />
          <div className={styles.total}>
            <h4
              style={{
                fontSize: 48,
                fontWeight: 700,
              }}
            >
              {new Intl.NumberFormat("ru-RU").format(
                data
                  .filter((item, index) => !activeIndex.includes(index))
                  .reduce((acc, item) => acc + item.value, 0),
              )}{" "}
            </h4>

            <h6
              style={{
                fontSize: 24,
                fontWeight: 400,
                marginTop: 13,
              }}
            >
              {props.getLocalisationMessage("in_total", "In Total")}
            </h6>
          </div>
        </FlexBox>
      )}
    </Card>
  );
}

export default enhancer(CustomerOrderTotalCountByServiceTypeChartWrapper);
