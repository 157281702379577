import React, { useEffect, useState } from "react";
import { compose, getContext } from "recompose";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FitBounds, InfoWindow } from "react-leflet-map-components";
import { getMessage } from "../../reducers/LocalizationReducer";
import FlexBox from "../../components/ui-core/FlexBox";
import PageLoading from "../../components/ui-core/PageLoading";
import LeafletMapWrapper from "../../components/maps-leaflet/LeafletMapWrapper";
import CustomerAppLayout from "../../components/customer/CustomerAppLayout";
import { getFormValues, reduxForm } from "redux-form";
import { getCurrentLocation } from "../../components/maps/Geolocation";
import { isValidCoordinates } from "../../helpers/ValidateUtils";
// eslint-disable-next-line import/no-internal-modules
import { PanTo as LeafletPanTo } from "react-leflet-map-components/lib/animations/PanTo";
import _ from "lodash";
import { showErrorMessage } from "../../reducers/NotificationsReducer";
import { Close, MyLocation } from "@material-ui/icons";
import { Avatar, IconButton, makeStyles } from "@material-ui/core";
import FormJMOrPostcodeAutoComplete from "../../components/form/FormJMOrPostcodeAutoComplete";
import { getPostcodesByJM } from "../../api/admin/AdminPostcodesApi";
import { getJurisdictionByLocation } from "../../api/admin/AdminJurisdictionsApi";
import LeafletMapDriversCluster from "../../components/maps/osm/LeafletMapDriversCluster";

const getValues = getFormValues("CustomerPostcodesCoordinatesContainer");

const enhancer = compose(
  connect(state => {
    const getLocalisationMessage = (code, defaultMessage) =>
      getMessage(state, code, defaultMessage);

    return {
      getLocalisationMessage,
    };
  }),
  getContext({ setLocationQuery: PropTypes.func.isRequired }),
  reduxForm({
    enableReinitialize: true,
    form: "CustomerPostcodesCoordinatesContainer",
  }),
  connect(state => ({
    values: getValues(state),
  })),
);

CustomerPostcodesCoordinatesContainer.propTypes = {
  values: PropTypes.object,
  params: PropTypes.object,
  getLocalisationMessage: PropTypes.func,
  change: PropTypes.func,
};
const useStyles = makeStyles({
  root: { position: "relative" },
  map: { flex: "1 1 0%", zIndex: 0 },
  tools: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1000,
    width: "400px",
    height: "100%",
    background: "white",
    padding: "1rem .5rem",
  },
  postcodeWrapper: {
    minHeight: "60px",
    cursor: "pointer",
    transition: "all .4s ease",
    borderRadius: "4px",
    "&:hover": {
      "&>*": {
        backgroundColor: "#F6F7F7",
        color: "#183E98",
      },
    },
  },
  pTB: {
    padding: ".5rem 0",
  },
  popupStyle: {
    "& .leaflet-popup-content": {
      margin: "0 19px 15px",
    },
  },
});

function CustomerPostcodesCoordinatesContainer(props) {
  const classes = useStyles();
  const { getLocalisationMessage, values = {}, change, params } = props;
  const { jurisdiction } = values;
  const [location, setLocation] = useState({
    lat: params.lat || 41.311081,
    lng: params.lng || 69.240562,
  });
  const [postcodes, setPostcodes] = useState([]);
  // const [isLoadLocation, setIsLoadLocation] = useState(true);
  const [isLoadAutoCurrentLocation, setIsLoadAutoCurrentLocation] = useState(
    false,
  );
  const [isUserSetLocation, setIsUserSetLocation] = useState(false);
  const [activePostcode, setActivePostcode] = useState({});

  useEffect(() => {
    getPostcodesByJM(jurisdiction && jurisdiction.id)
      .then(res => {
        setPostcodes(_.get(res, "data").filter(v => v.lat));
      })
      .catch(error => showErrorMessage(error));
  }, [location]);
  useEffect(() => {
    if (!jurisdiction && location) {
      // setIsLoadLocation(false);
      getJurisdictionByLocation(location)
        .then(res => {
          // setIsLoadLocation(true);
          if (_.get(res.data, "parent")) {
            change("jurisdiction", _.omit(res.data, "parent"));
          } else {
            change("jurisdiction", null);
          }
        })
        .catch(error => {
          // setIsLoadLocation(true);
          showErrorMessage(error);
        });
    }
  }, [location]);
  useEffect(() => {
    if (!isUserSetLocation && jurisdiction && jurisdiction.lat)
      setLocation({
        lat: jurisdiction.lat,
        lng: jurisdiction.lng,
      });
  }, [jurisdiction]);
  useEffect(() => {
    if (isLoadAutoCurrentLocation) {
      setIsUserSetLocation(true);
      setIsLoadAutoCurrentLocation(false);
      getCurrentLocation(({ coords: { latitude, longitude } }) => {
        setLocation({
          lat: latitude,
          lng: longitude,
        });
      });
    }
  }, [isLoadAutoCurrentLocation]);

  const isActivePostcode = postcode => activePostcode.id === postcode.id;

  return (
    <CustomerAppLayout
      slug="coordinates_of_NBHD"
      title={getLocalisationMessage(
        "coordinates_of_NBHD",
        "Coordinates of Neighborhoods",
      )}
    >
      <PageLoading isLoading={false} />

      <FlexBox flex={true} className={classes.root}>
        <FlexBox gutter={8} flex={true}>
          <FlexBox className={classes.tools}>
            <FlexBox flex={true} direction="column">
              <FlexBox style={{ marginTop: "1rem" }}>
                <FormJMOrPostcodeAutoComplete
                  fullWidth={true}
                  name="jurisdiction"
                  label={getLocalisationMessage("jurisdiction", "Jurisdiction")}
                  renderOption={option => (
                    <FlexBox direction="column">
                      <span>{option.name}</span>
                      <span
                        style={{
                          fontSize: ".8rem",
                          fontStyle: "italic",
                        }}
                      >
                        {option.value.hierarchy.map((item, index, arr) =>
                          index === arr.length - 1
                            ? item.name
                            : `${item.name} > `,
                        )}
                      </span>
                    </FlexBox>
                  )}
                  onChange={() => {
                    setIsUserSetLocation(false);
                    setActivePostcode({});
                  }}
                />
              </FlexBox>

              <FlexBox
                flex={true}
                direction="column"
                style={{ padding: ".5rem", overflow: "overlay" }}
              >
                {postcodes.length > 0 &&
                  postcodes
                    .sort((a, b) =>
                      a.id === activePostcode.id
                        ? -1
                        : b.id === activePostcode.id
                        ? 1
                        : a.id - b.id,
                    )
                    .map((v, index) => (
                      <FlexBox
                        key={index}
                        direction="column"
                        className={classes.postcodeWrapper}
                        onClick={() => {
                          setActivePostcode(v);
                          setIsUserSetLocation(true);
                          setLocation({
                            lat: v.lat,
                            lng: v.lon,
                          });
                        }}
                      >
                        <span
                          style={{
                            minHeight: "60px",
                            color: isActivePostcode(v) ? "#183E98" : "#1B1B1F",
                            fontSize: "18px",
                            padding: ".5rem",
                            borderBottom: "1px solid #C2C4C7",
                            borderColor: isActivePostcode(v)
                              ? "#183E98"
                              : "#C2C4C7",
                          }}
                        >
                          <p style={{ margin: 0 }}>
                            {getLocalisationMessage("postcode")}: {"  "}
                            <strong>{v.name}</strong>
                          </p>
                          <p
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              maxWidth: "360px",
                            }}
                          >
                            {getLocalisationMessage("address")}: {"  "}
                            <strong>{v.address}</strong>
                          </p>
                        </span>
                      </FlexBox>
                    ))}
              </FlexBox>
            </FlexBox>
          </FlexBox>

          <FlexBox flex={true}>
            <LeafletMapWrapper
              zoom={14}
              className={classes.popupStyle}
              onClick={e => {
                setIsUserSetLocation(true);
                setLocation({ lat: e.latlng.lat, lng: e.latlng.lng });
              }}
            >
              {isValidCoordinates(location) && (
                <div>
                  <LeafletPanTo latLng={location} />
                </div>
              )}
              {activePostcode &&
                activePostcode.id &&
                isActivePostcode(activePostcode) && (
                  <React.Fragment>
                    <InfoWindow
                      open={true}
                      position={{
                        lat: activePostcode.lat,
                        lng: activePostcode.lon,
                      }}
                      closeButton={false}
                    >
                      <FlexBox
                        direction="column"
                        style={{
                          width: "130px",
                          overflow: "hidden",
                        }}
                      >
                        <FlexBox
                          flex={true}
                          justify="flex-end"
                          style={{ marginRight: "-9px" }}
                        >
                          <IconButton
                            style={{ width: 30, height: 30 }}
                            onClick={() => setActivePostcode({})}
                          >
                            <Close fontSize="small" />
                          </IconButton>
                        </FlexBox>
                        <FlexBox className={classes.pTB}>
                          <strong>
                            {getLocalisationMessage("postcode")}: {"  "}
                            {activePostcode.name}
                          </strong>
                        </FlexBox>
                        <FlexBox className={classes.pTB}>
                          <strong>
                            {getLocalisationMessage("address")}: {"  "}
                            {activePostcode.address}
                          </strong>
                        </FlexBox>
                      </FlexBox>
                    </InfoWindow>
                  </React.Fragment>
                )}
              {postcodes && postcodes.length > 0 && (
                <LeafletMapDriversCluster
                  onClick={item => {
                    setActivePostcode(item);
                    setIsUserSetLocation(true);
                    setLocation({
                      lat: item.lat,
                      lng: item.lon,
                    });
                  }}
                  locations={postcodes}
                />
              )}
              {activePostcode && activePostcode.id && (
                <FitBounds
                  latLngBounds={[
                    {
                      lat: activePostcode.lat,
                      lng: activePostcode.lon,
                    },
                  ]}
                />
              )}
              <Avatar
                onClick={() => setIsLoadAutoCurrentLocation(true)}
                style={{
                  color: "#183E98",
                  backgroundColor: "white",
                  position: "absolute",
                  bottom: "6.5rem",
                  right: "10px",
                  border: "2px solid rgba(0,0,0,0.2)",
                  zIndex: 999,
                  width: "34px",
                  height: "34px",
                  cursor: "pointer",
                }}
              >
                <MyLocation />
              </Avatar>
            </LeafletMapWrapper>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </CustomerAppLayout>
  );
}

export default enhancer(CustomerPostcodesCoordinatesContainer);
